import { responseHandler } from "../_heleper/response-handler";
import {url} from "./apiurl"
import {authHeader} from '../_heleper/auth-header'

const authApi = {

  login: (data) => {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(
      `${url}/auth/customer/login`,
      reqOptions
    ).then(responseHandler).then(data=>{
      sessionStorage.setItem('accessToken', JSON.stringify(data.token));
      return data;
    });
  },
  
  register: (data) => {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(
      `${url}/auth/customer/register-login`,
      reqOptions
    ).then(responseHandler).then(data=>{
      sessionStorage.setItem('accessToken', JSON.stringify(data.token));
      return data;
    });
  },

  vendorRegister: (data) => {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(
      `${url}/auth/customer/register/vendor`,
      reqOptions
    ).then(responseHandler)
  },

  agentRegister: (data) => {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(
      `${url}/auth/customer/register/agent`,
      reqOptions
    ).then(responseHandler)
  },

  quickRegister: (data) => {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    return fetch(
      `${url}/auth/customer/register-quick`,
      reqOptions
    ).then(responseHandler);
  },

  getProfile: () => {
    const reqOptions = {
      method: "GET",
      headers: {...authHeader(), "Content-Type": "application/json" },      
    };
    return fetch(
      `${url}/auth/customer/profile`,
      reqOptions
    ).then(responseHandler);
  },

  updateProfile: (data) => {
    const reqOptions = {
      method: "PUT",
      headers: {...authHeader(), "Content-Type": "application/json" },
      body:JSON.stringify(data)      
    };
    return fetch(
      `${url}/auth/customer/profile`,
      reqOptions
    ).then(responseHandler);
  },

  addWishlist:(data)=>{
    const reqOptions = {
      method:'PUT',
      headers:{...authHeader(), "Content-Type":"application/json"},
      body:JSON.stringify(data)
    }
    return fetch(`${url}/auth/customer/wishlist-add`, reqOptions).then(responseHandler)
  },
  
  removeWishlist:(data)=>{
    const reqOptions = {
      method:"PUT",
      headers:{...authHeader(), "Content-Type":"application/json"},
      body:JSON.stringify(data)
    }
    return fetch(`${url}/auth/customer/wishlist-rm`, reqOptions).then(responseHandler)
  }
};

export default authApi;
